import {
  Alert,
  Box,
  Button,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PageTitleTypography } from "../../components/Element/Typography/PageTitleTypography";
import { CheckCircle, OpenInNew } from "@mui/icons-material";
import { SubjectChip } from "../../components/Element/Chip/SubjectChip";
import { Subject } from "../../domains/subject.enum";

export const ExamReviewListPage = () => {
  // 通常通り: <Chip color="info" icon={<CheckCircle color="info" />} label="通常通り" />
  // 混雑中: <Chip color="warning" icon={<Error color="warning" />} label="混雑中" />
  // 休止中: <Chip color="error" icon={<Cancel color="error" />} label="休止中"  />

  return (
    <Box sx={{ width: "100%" }}>
      <PageTitleTypography>模試・過去問添削</PageTitleTypography>
      <Alert severity="info" sx={{ my: 1 }}>
        本サービスの説明につきましては、学習の手引きもしくは
        <Link
          component="a"
          href="https://www.growy.education/services#exam-review"
          target="_blank"
          rel="noreferrer"
        >
          Webサイト
        </Link>
        をご覧ください
      </Alert>
      <Alert severity="info" sx={{ my: 1 }}>
        現在、模試・過去問添削はGoogleFormでの受付となっております
      </Alert>
      <Alert severity="error" sx={{ my: 1 }}>
        模試・過去問添削は、1教材あたり5500円の追加料金がかかります
      </Alert>
      <Box p={2}>
        <Button
          component="a"
          target="_blank"
          href="https://forms.gle/NjPuRp4cZaLHVHGg6"
          rel="noreferrer"
          variant="outlined"
          endIcon={<OpenInNew color="primary" />}
        >
          GoogleFormを開く
        </Button>
      </Box>
      <Box p={2}>
        <Typography>現在の受付状況</Typography>
        <Table aria-label="exam-review status">
          <TableHead>
            <TableRow>
              <TableCell>科目</TableCell>
              <TableCell>混雑状況</TableCell>
              <TableCell>予想日数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <SubjectChip subject={Subject.JAPANESE} />
              </TableCell>
              <TableCell>
                <Chip
                  color="info"
                  icon={<CheckCircle color="info" />}
                  label="通常通り"
                />
              </TableCell>
              <TableCell>３日</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <SubjectChip subject={Subject.MATHEMATICS} />
              </TableCell>
              <TableCell>
                <Chip
                  color="info"
                  icon={<CheckCircle color="info" />}
                  label="通常通り"
                />
              </TableCell>
              <TableCell>３日</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <SubjectChip subject={Subject.SCIENCE} />
              </TableCell>
              <TableCell>
                <Chip
                  color="info"
                  icon={<CheckCircle color="info" />}
                  label="通常通り"
                />
              </TableCell>
              <TableCell>３日</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <SubjectChip subject={Subject.SOCIALSTUDIES} />
              </TableCell>
              <TableCell>
                <Chip
                  color="info"
                  icon={<CheckCircle color="info" />}
                  label="通常通り"
                />
              </TableCell>
              <TableCell>３日</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
